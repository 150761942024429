.navbar-collapse{
    flex-grow: inherit !important;
  }
  @media (min-width: 992px){
.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}
  }

